
import { array, object } from 'vue-types'
import { mapGetters } from 'vuex'
import FavoriteComponent from '../track/FavoriteComponent'
import DownloadTrackComponent from '../track/DownloadTrackComponent'
import SharedTrackComponent from '../track/SharedTrackComponent'
import CartComponent from '../track/CartComponent'
import SameTrackComponent from '../track/SameTrackComponent'
import DownloadMediaComponent from '../track/DownloadMediaComponent'
import DownloadDocumentComponent from '../track/DownloadDocumentComponent'
import EditTrackComponent from '../track/EditTrackComponent'
import StatisticsTrackComponent from '../track/StatisticsTrackComponent'

export default {
  name: 'IconsListComponent',
  components: {
    StatisticsTrackComponent,
    EditTrackComponent,
    DownloadMediaComponent,
    SameTrackComponent,
    CartComponent,
    SharedTrackComponent,
    DownloadTrackComponent,
    FavoriteComponent,
    DownloadDocumentComponent,
  },
  props: {
    iconsList: array().def([]),
    track: object().required,
  },

  data() {
    return {
      showActions: false,
    }
  },
  computed: {
    ...mapGetters('user', ['mySlug']),
  },
  methods: {
    toggleShow() {
      this.showActions = !this.showActions
    },
    closeActions() {
      this.showActions = false
    },
  },
}
