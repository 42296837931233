export const BreadcrumbsComponent = () => import('../../components/common/BreadcrumbsComponent.vue' /* webpackChunkName: "components/breadcrumbs-component" */).then(c => wrapFunctional(c.default || c))
export const ButtonComponent = () => import('../../components/common/ButtonComponent.vue' /* webpackChunkName: "components/button-component" */).then(c => wrapFunctional(c.default || c))
export const CheckboxComponent = () => import('../../components/common/CheckboxComponent.vue' /* webpackChunkName: "components/checkbox-component" */).then(c => wrapFunctional(c.default || c))
export const ErrorComponent = () => import('../../components/common/ErrorComponent.vue' /* webpackChunkName: "components/error-component" */).then(c => wrapFunctional(c.default || c))
export const FooterComponent = () => import('../../components/common/FooterComponent.vue' /* webpackChunkName: "components/footer-component" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenu = () => import('../../components/common/HeaderMenu.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c))
export const InputComponent = () => import('../../components/common/InputComponent.vue' /* webpackChunkName: "components/input-component" */).then(c => wrapFunctional(c.default || c))
export const ObserverComponent = () => import('../../components/common/ObserverComponent.vue' /* webpackChunkName: "components/observer-component" */).then(c => wrapFunctional(c.default || c))
export const PaginationComponent = () => import('../../components/common/PaginationComponent.vue' /* webpackChunkName: "components/pagination-component" */).then(c => wrapFunctional(c.default || c))
export const PlayerComponent = () => import('../../components/common/PlayerComponent.vue' /* webpackChunkName: "components/player-component" */).then(c => wrapFunctional(c.default || c))
export const SearchComponent = () => import('../../components/common/SearchComponent.vue' /* webpackChunkName: "components/search-component" */).then(c => wrapFunctional(c.default || c))
export const ShareNetworks = () => import('../../components/common/ShareNetworks.vue' /* webpackChunkName: "components/share-networks" */).then(c => wrapFunctional(c.default || c))
export const ThreeDotsSpinner = () => import('../../components/common/ThreeDotsSpinner.vue' /* webpackChunkName: "components/three-dots-spinner" */).then(c => wrapFunctional(c.default || c))
export const TitleComponent = () => import('../../components/common/TitleComponent.vue' /* webpackChunkName: "components/title-component" */).then(c => wrapFunctional(c.default || c))
export const TopMenu = () => import('../../components/common/TopMenu.vue' /* webpackChunkName: "components/top-menu" */).then(c => wrapFunctional(c.default || c))
export const TrackComponent = () => import('../../components/common/TrackComponent.vue' /* webpackChunkName: "components/track-component" */).then(c => wrapFunctional(c.default || c))
export const UserMenuComponent = () => import('../../components/common/UserMenuComponent.vue' /* webpackChunkName: "components/user-menu-component" */).then(c => wrapFunctional(c.default || c))
export const AddToCartIcon = () => import('../../components/svg/addToCartIcon.vue' /* webpackChunkName: "components/add-to-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const ArrowPaginationIcon = () => import('../../components/svg/arrowPaginationIcon.vue' /* webpackChunkName: "components/arrow-pagination-icon" */).then(c => wrapFunctional(c.default || c))
export const DeleteIcon = () => import('../../components/svg/deleteIcon.vue' /* webpackChunkName: "components/delete-icon" */).then(c => wrapFunctional(c.default || c))
export const DownloadDocumentIcon = () => import('../../components/svg/downloadDocumentIcon.vue' /* webpackChunkName: "components/download-document-icon" */).then(c => wrapFunctional(c.default || c))
export const InCartIcon = () => import('../../components/svg/inCartIcon.vue' /* webpackChunkName: "components/in-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const LikeIcon = () => import('../../components/svg/likeIcon.vue' /* webpackChunkName: "components/like-icon" */).then(c => wrapFunctional(c.default || c))
export const LikeIconActive = () => import('../../components/svg/likeIconActive.vue' /* webpackChunkName: "components/like-icon-active" */).then(c => wrapFunctional(c.default || c))
export const PauseSmallIcon = () => import('../../components/svg/pauseSmallIcon.vue' /* webpackChunkName: "components/pause-small-icon" */).then(c => wrapFunctional(c.default || c))
export const PlaySmallIcon = () => import('../../components/svg/playSmallIcon.vue' /* webpackChunkName: "components/play-small-icon" */).then(c => wrapFunctional(c.default || c))
export const ReturnToCart = () => import('../../components/svg/returnToCart.vue' /* webpackChunkName: "components/return-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AccountPageView = () => import('../../components/views/AccountPageView.vue' /* webpackChunkName: "components/account-page-view" */).then(c => wrapFunctional(c.default || c))
export const AuthorSlugPageView = () => import('../../components/views/AuthorSlugPageView.vue' /* webpackChunkName: "components/author-slug-page-view" */).then(c => wrapFunctional(c.default || c))
export const AuthorsPageView = () => import('../../components/views/AuthorsPageView.vue' /* webpackChunkName: "components/authors-page-view" */).then(c => wrapFunctional(c.default || c))
export const CatalogPageView = () => import('../../components/views/CatalogPageView.vue' /* webpackChunkName: "components/catalog-page-view" */).then(c => wrapFunctional(c.default || c))
export const CatalogSlugPageView = () => import('../../components/views/CatalogSlugPageView.vue' /* webpackChunkName: "components/catalog-slug-page-view" */).then(c => wrapFunctional(c.default || c))
export const MainPageView = () => import('../../components/views/MainPageView.vue' /* webpackChunkName: "components/main-page-view" */).then(c => wrapFunctional(c.default || c))
export const AccountPageAccordionItem = () => import('../../components/accountPage/AccordionItem.vue' /* webpackChunkName: "components/account-page-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const AccountPageAsideMenuComponent = () => import('../../components/accountPage/AsideMenuComponent.vue' /* webpackChunkName: "components/account-page-aside-menu-component" */).then(c => wrapFunctional(c.default || c))
export const CartPageInvoiceComponent = () => import('../../components/cartPage/InvoiceComponent.vue' /* webpackChunkName: "components/cart-page-invoice-component" */).then(c => wrapFunctional(c.default || c))
export const CartPageOrderTitleComponent = () => import('../../components/cartPage/OrderTitleComponent.vue' /* webpackChunkName: "components/cart-page-order-title-component" */).then(c => wrapFunctional(c.default || c))
export const CatalogPageFilterComponent = () => import('../../components/catalogPage/FilterComponent.vue' /* webpackChunkName: "components/catalog-page-filter-component" */).then(c => wrapFunctional(c.default || c))
export const CatalogPageFiltersComponent = () => import('../../components/catalogPage/FiltersComponent.vue' /* webpackChunkName: "components/catalog-page-filters-component" */).then(c => wrapFunctional(c.default || c))
export const CatalogPageSliderRangeComponent = () => import('../../components/catalogPage/SliderRangeComponent.vue' /* webpackChunkName: "components/catalog-page-slider-range-component" */).then(c => wrapFunctional(c.default || c))
export const MainPageBannerMainPage = () => import('../../components/mainPage/BannerMainPage.vue' /* webpackChunkName: "components/main-page-banner-main-page" */).then(c => wrapFunctional(c.default || c))
export const MainPageContentMainPage = () => import('../../components/mainPage/ContentMainPage.vue' /* webpackChunkName: "components/main-page-content-main-page" */).then(c => wrapFunctional(c.default || c))
export const TrackAuthorComponent = () => import('../../components/track/AuthorComponent.vue' /* webpackChunkName: "components/track-author-component" */).then(c => wrapFunctional(c.default || c))
export const TrackCartComponent = () => import('../../components/track/CartComponent.vue' /* webpackChunkName: "components/track-cart-component" */).then(c => wrapFunctional(c.default || c))
export const TrackDownloadDocumentComponent = () => import('../../components/track/DownloadDocumentComponent.vue' /* webpackChunkName: "components/track-download-document-component" */).then(c => wrapFunctional(c.default || c))
export const TrackDownloadMediaComponent = () => import('../../components/track/DownloadMediaComponent.vue' /* webpackChunkName: "components/track-download-media-component" */).then(c => wrapFunctional(c.default || c))
export const TrackDownloadTrackComponent = () => import('../../components/track/DownloadTrackComponent.vue' /* webpackChunkName: "components/track-download-track-component" */).then(c => wrapFunctional(c.default || c))
export const TrackDurationComponent = () => import('../../components/track/DurationComponent.vue' /* webpackChunkName: "components/track-duration-component" */).then(c => wrapFunctional(c.default || c))
export const TrackEditTrackComponent = () => import('../../components/track/EditTrackComponent.vue' /* webpackChunkName: "components/track-edit-track-component" */).then(c => wrapFunctional(c.default || c))
export const TrackEmailComponent = () => import('../../components/track/EmailComponent.vue' /* webpackChunkName: "components/track-email-component" */).then(c => wrapFunctional(c.default || c))
export const TrackFavoriteComponent = () => import('../../components/track/FavoriteComponent.vue' /* webpackChunkName: "components/track-favorite-component" */).then(c => wrapFunctional(c.default || c))
export const TrackIconsListComponent = () => import('../../components/track/IconsListComponent.vue' /* webpackChunkName: "components/track-icons-list-component" */).then(c => wrapFunctional(c.default || c))
export const TrackSameTrackComponent = () => import('../../components/track/SameTrackComponent.vue' /* webpackChunkName: "components/track-same-track-component" */).then(c => wrapFunctional(c.default || c))
export const TrackSharedTrackComponent = () => import('../../components/track/SharedTrackComponent.vue' /* webpackChunkName: "components/track-shared-track-component" */).then(c => wrapFunctional(c.default || c))
export const TrackStatisticsTrackComponent = () => import('../../components/track/StatisticsTrackComponent.vue' /* webpackChunkName: "components/track-statistics-track-component" */).then(c => wrapFunctional(c.default || c))
export const TrackThumbnailComponent = () => import('../../components/track/ThumbnailComponent.vue' /* webpackChunkName: "components/track-thumbnail-component" */).then(c => wrapFunctional(c.default || c))
export const TrackTitleComponent = () => import('../../components/track/TitleComponent.vue' /* webpackChunkName: "components/track-title-component" */).then(c => wrapFunctional(c.default || c))
export const TrackWaveSurferComponent = () => import('../../components/track/WaveSurferComponent.vue' /* webpackChunkName: "components/track-wave-surfer-component" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
